import React from 'react';

const CLIENT_ID = '1307716735603966064';
const REDIRECT_URI = 'http://164.92.101.175/callback';

const Login = () => {
  const handleLogin = () => {
    const DISCORD_AUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
      REDIRECT_URI
    )}&response_type=code&scope=identify`;
    window.location.href = DISCORD_AUTH_URL;
  };

  return (
    <div className="h-screen bg-gray4 flex flex-col justify-between">
      {/* Title Section */}
      <header className="w-full text-center bg-gray3 py-6">
        <h1 className="text-gray5 text-4xl font-logo">OnlyThorn</h1>
      </header>

      {/* Login Button Section */}
      <div className="flex-grow flex items-center justify-center">
        <div className="text-center bg-gray3 p-6 rounded-lg shadow-lg">
          <button
            className="bg-gray5 hover:bg-gray6 text-white px-6 py-3 rounded-lg font-sans font-bold"
            onClick={handleLogin}
          >
            Login Via Discord
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
